<script setup>
// TODO update to boiler plate version
import Error from '@/Components/Input/Error.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    label: {},
    value: {},
    valueSymbol: String,
    errors: [],
    disabled: Boolean,
});
const emit = defineEmits(['update:value']);

const locale = computed(() => usePage().props.locale);

const displayValue = computed(() => {
    let money = (props.value / 100).toFixed(2);
    if (locale.value == 'nl') {
        return String(money).replace('.', ',');
    }
    return money;
});

const emitValue = (value) => {
    value = value.target.value;

    let asString = String(value)
        .replace(',', '.')
        .replace(/[^\d.-]/g, '');

    let asFloat = parseFloat(asString);

    if (isNaN(asFloat)) {
        asFloat = 0;
    }

    emit('update:value', Math.round(asFloat * 100));
};
</script>

<template>
    <div :class="theme.global.outer">
        <Label :label="label" />
        <div :class="{ 'flex items-center': valueSymbol }">
            <span v-if="valueSymbol" class="pr-1">{{ valueSymbol }}</span>
            <input
                :id="label ?? 'input'"
                type="text"
                :class="theme.global.input"
                :value="displayValue"
                @blur="emitValue"
                @change="emitValue"
                :disabled="disabled"
            />
        </div>
        <Error :error="errors" />
    </div>
</template>
